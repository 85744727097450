<template>
  <div class="details hidden-scrollbar">
    <van-nav-bar title="居众AI数据" left-arrow @click-left="onClickLeft" />
    <van-row class="p-h-10 m-v-10">
      <van-col span="8" class="text-c">
        起：<Selector
          :actions="monList"
          :defaultValue="year_mon_second"
          @onSelect="selectSecond"
        ></Selector>
      </van-col>
      <van-col span="8" class="text-c">
        止：<Selector
          :actions="monList"
          :defaultValue="year_mon_first"
          @onSelect="selectFirst"
        ></Selector>
      </van-col>
      <van-col span="8" class="text-c">
        <Selector
          :actions="workList"
          :defaultValue="is_work"
          @onSelect="selectWork"
        ></Selector>
      </van-col>
    </van-row>
    <div class="table">
      <div class="tab-row first-line">
        <div class="tab-col tab-col-c tab-col-w-15">分公司</div>
        <div class="tab-col tab-col-c tab-col-w-85">
          <div class="table">
            <div class="tab-row none-line">
              <div class="tab-col tab-col-c tab-col-w-20">行政部</div>
              <div class="tab-col tab-col-c tab-col-w-20">设计部</div>
              <div class="tab-col tab-col-c tab-col-w-20">市场部</div>
              <div class="tab-col tab-col-c tab-col-w-20">助理部</div>
              <div class="tab-col tab-col-c tab-col-w-20">工程部</div>
              <div class="tab-col tab-col-c tab-col-w-20">年月份</div>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="['tab-row', { 'last-line': item.isEnd }]"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <div
          :class="[
            'tab-col tab-col-c tab-col-w-15',
            { 'first-col': !item.isEnd },
          ]"
        >
          {{ item.org_name }}
        </div>
        <div class="tab-col tab-col-c tab-col-w-85">
          <div class="table">
            <div class="tab-row">
              <div class="tab-col tab-col-c tab-col-w-20">
                {{ item.data_first.number1 }}
              </div>
              <div class="tab-col tab-col-c tab-col-w-20">
                {{ item.data_first.number2 }}
              </div>
              <div class="tab-col tab-col-c tab-col-w-20">
                {{ item.data_first.number3 }}
              </div>
              <div class="tab-col tab-col-c tab-col-w-20">
                {{ item.data_first.number4 }}
              </div>
              <div class="tab-col tab-col-c tab-col-w-20">
                {{ item.data_first.number5 }}
              </div>
              <div class="tab-col tab-col-c tab-col-w-20">
                {{ item.data_first.year_mon }}
              </div>
            </div>
            <div class="tab-row none-line">
              <div class="tab-col tab-col-c tab-col-w-20">
                {{ item.data_second.number1 }}
              </div>
              <div class="tab-col tab-col-c tab-col-w-20">
                {{ item.data_second.number2 }}
              </div>
              <div class="tab-col tab-col-c tab-col-w-20">
                {{ item.data_second.number3 }}
              </div>
              <div class="tab-col tab-col-c tab-col-w-20">
                {{ item.data_second.number4 }}
              </div>
              <div class="tab-col tab-col-c tab-col-w-20">
                {{ item.data_second.number5 }}
              </div>
              <div class="tab-col tab-col-c tab-col-w-20">
                {{ item.data_second.year_mon }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRouter } from "vue-router";
import { NavBar, Row, Col } from "vant";
import Selector from "@/components/select/selector.vue";

export default defineComponent({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    Selector,
  },
  setup() {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;
    let router = useRouter();

    const dataMap = reactive({
      workList: [{ text: "在职" }, { text: "离职" }],
      is_work: "在职",
      monList: [] as Action[],
      year_mon_first: "",
      year_mon_second: "",
      title: "",
      dataList: [] as ReportShrUserDepartMonReq["data"],
    });

    const onClickLeft = () => {
      router.push({
        name: "AIData",
      });
    };

    const getYearMon = () => {
      store
        .dispatch("actReportShrUserYearMon", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            res.data_list.year_mon.forEach((el) => {
              dataMap.monList.push({
                text: el,
              });
            });
            dataMap.year_mon_first = res.data_list.year_mon_first;
            dataMap.year_mon_second = res.data_list.year_mon_second;
            getShrUserDepartMon();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getShrUserDepartMon = () => {
      store
        .dispatch("actReportShrUserDepartMon", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          is_work: dataMap.is_work === "在职" ? 1 : 0,
          region: store.state.app.reportConfig?.region || "",
          year_mon_first: dataMap.year_mon_first,
          year_mon_second: dataMap.year_mon_second,
        })
        .then((res) => {
          dataMap.dataList = res.data;
          dataMap.title = res.title;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const selectFirst = (index: number, action: Action) => {
      dataMap.year_mon_first = action.text;
      getShrUserDepartMon();
    };
    const selectSecond = (index: number, action: Action) => {
      dataMap.year_mon_second = action.text;
      getShrUserDepartMon();
    };
    const selectWork = (index: number, action: Action) => {
      dataMap.is_work = action.text;
      getShrUserDepartMon();
    };

    getYearMon();
    return {
      ...toRefs(dataMap),
      onClickLeft,
      selectFirst,
      selectSecond,
      selectWork,
    };
  },
});
</script>

<style lang="less" scoped>
.details {
  height: 100vh;
  width: 100vw;
  overflow: auto;
}
</style>