
import { defineComponent, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { Store } from "@/store/";
import { useRouter } from "vue-router";
import { NavBar, Row, Col } from "vant";
import Selector from "@/components/select/selector.vue";

export default defineComponent({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    Selector,
  },
  setup() {
    const store: Store = useStore();
    const { userid, username, dept_id } = store.state.app;
    let router = useRouter();

    const dataMap = reactive({
      workList: [{ text: "在职" }, { text: "离职" }],
      is_work: "在职",
      monList: [] as Action[],
      year_mon_first: "",
      year_mon_second: "",
      title: "",
      dataList: [] as ReportShrUserDepartMonReq["data"],
    });

    const onClickLeft = () => {
      router.push({
        name: "AIData",
      });
    };

    const getYearMon = () => {
      store
        .dispatch("actReportShrUserYearMon", {
          userid: userid,
          username: username,
          dept_id: dept_id,
        })
        .then((res) => {
          if (res.status == 200) {
            res.data_list.year_mon.forEach((el) => {
              dataMap.monList.push({
                text: el,
              });
            });
            dataMap.year_mon_first = res.data_list.year_mon_first;
            dataMap.year_mon_second = res.data_list.year_mon_second;
            getShrUserDepartMon();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const getShrUserDepartMon = () => {
      store
        .dispatch("actReportShrUserDepartMon", {
          userid: userid,
          username: username,
          dept_id: dept_id,
          is_work: dataMap.is_work === "在职" ? 1 : 0,
          region: store.state.app.reportConfig?.region || "",
          year_mon_first: dataMap.year_mon_first,
          year_mon_second: dataMap.year_mon_second,
        })
        .then((res) => {
          dataMap.dataList = res.data;
          dataMap.title = res.title;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const selectFirst = (index: number, action: Action) => {
      dataMap.year_mon_first = action.text;
      getShrUserDepartMon();
    };
    const selectSecond = (index: number, action: Action) => {
      dataMap.year_mon_second = action.text;
      getShrUserDepartMon();
    };
    const selectWork = (index: number, action: Action) => {
      dataMap.is_work = action.text;
      getShrUserDepartMon();
    };

    getYearMon();
    return {
      ...toRefs(dataMap),
      onClickLeft,
      selectFirst,
      selectSecond,
      selectWork,
    };
  },
});
